<template>
  <div class="borkerFormTwo">
    <div class="textBigBox">
      <div class="centerBox">
        <div class="leftBox">
          <div class="textTittle">
            <span>經紀類型</span>
          </div>
          <div class="itemBox">
            <Validator type="drowDownMeun" defaultName="Select" :titleName="form.borkerTitle" :show="chickShow" @isCheckOkValue="borkerTitleCheck">
              <select-menu :title="form.borkerTitle" :width='196'>
                <option-menu v-for="item in borkerList" :key="item.id" @click="borker(item)">{{item.enName}}</option-menu>
              </select-menu>
            </Validator>
          </div>
        </div>
        <div class="rightBox">
          <div class="textTittle">
            <span>券商行號</span>
            <span class="span2">(非必填)</span>
          </div>
          <div class="textSpan">
            <span>如果您是隸屬於已在Finger註冊的券商經銷商，請輸入券商的券商編號。</span>
          </div>
          <div class="inputBox">
            <Validator type="number" :value="form.BrokerageNumber" :show="chickShow" @isCheckOkValue="BrokerageNumberCheck">
              <input-text placeholder="303 Meadowview Drive" v-model="form.BrokerageNumber"/>
            </Validator>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/XuComponents/form/InputText'
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import {getDictById} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
export default {
  name:'BorkerFormTwo',
  components: {
    InputText,
    SelectMenu,
    OptionMenu,
    Validator,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      borkerList:[],
      
      form:{
        borkerTitle:"Select",
        BrokerageNumber:""
      },
      isCheckAllOkForm:{
        borkerTitleCheck:false,
        BrokerageNumberCheck:false,
      },
      isFormOk:false,
    }
  },
  methods:{
    borker(item){
      this.form.borkerTitle = item.enName
    },
    borkerListGo(){
      getDictById(15000).then(res =>{
        this.borkerList = res.data.list
      })
    },
    borkerTitleCheck(CheckBoolen){
      this.isCheckAllOkForm.borkerTitleCheck = CheckBoolen
      this.changPage()
    },
    BrokerageNumberCheck(CheckBoolen){
      this.isCheckAllOkForm.BrokerageNumberCheck = CheckBoolen
      this.changPage()
    },
    changPage(){
      if(this.isCheckAllOkForm.borkerTitleCheck == true 
      && this.isCheckAllOkForm.BrokerageNumberCheck == true ){
        this.isFormOk = true
        this.$emit('isFormOk', this.isFormOk)
        this.$emit('form', this.form)
      }
      else{
        this.isFormOk = false
        this.$emit('isFormOk', this.isFormOk)
      }
    },
  },
  mounted(){
    this.borkerListGo()
  }
}
</script>

<style scoped>
.borkerFormTwo{
  width: 100%;
  height: 100%;
  display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
}
.centerBox{
  display: flex;
  padding-top: 226px;
}
.leftBox{
  margin-right: 69px;
}
.textTittle{
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}
.span2{
  font-size: 18px;
}
.textSpan{
  /* width: 300px; */
  max-width: 300px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.inputBox{
  max-width: 300px;
}
</style>
