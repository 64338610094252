<template>
  <div class="borkerFormOne">
    <div class="textBigBox">
      <div class="textBox" id="textBox1">
        <div class="text1">
          <span>监管及许可信息聲明</span>
        </div>
      </div>
      <div class="textBox" id="textBox2">
        <div class="text2">
          <span>
            如果想要註冊成為個人經紀，您必須在您所提供服務的國家擁有相關從業資格證書，並遵守您所在國家的法律，您的所有營銷行為均為個人行為，於Finger無關。<br/><br/>
            如果您隸屬於已在Finger註冊的券商或經紀商，請輸入您所服務公司的編號。
          </span>
        </div>
      </div>
      <div class="textBox" id="textBox3">
        <div class="text3">
          <span>
            請選擇符合您的條件
          </span>
        </div>
      </div>
      <div class="textBox" >
        <div class="text4">
          <div class="icons" id="checkbox1">
            <input class="checkbox" type="checkbox" v-model="inputValue">
          </div>
          <div class="icons" id="checkbox2">
            <span>
              您是否清楚閱讀了我們的免責聲明，及相關法律
            </span>
          </div>
        </div>
      </div>
      <div class="warringText" v-if="chickShow">
        <span>{{warring}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:'BorkerFormOne',
  components: {

  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      inputValue:false,
      isFormOk:false,
      warring:"*You need to chick it all"
    }
  },
  methods:{
    changPage(){
      if(this.inputValue == true){
        this.isFormOk = true
        this.warring = ""
        this.$emit('isFormOk', this.isFormOk)
      }
      else{
        this.isFormOk = false
        this.warring = "*You need to chick it all"
        this.$emit('isFormOk', this.isFormOk)
      }
    }
  },
  watch:{
    "inputValue"(){
      this.changPage()
    }
  }
}
</script>

<style scoped>
.borkerFormOne{
  width: 100%;
  height: 100%;
}
.textBigBox{
  width: 847px;
  height: 100%;
  margin: 0 auto;
  /* border: 1px red solid; */
}
.textBox{
  width: 100%;
  display: flex;
}
#textBox1{
  padding-top: 100px;
  margin-bottom: 44px;
}
#textBox2{
  margin-bottom: 123px;
}
#textBox3{
  margin-bottom: 33px;
}
.text1{
  margin: 0 auto;
  font-family: Inter;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.text2{
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.text3{
  margin: 0 auto;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.text4{
  margin: 0 auto;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  /* border: 1px red solid; */
}
.icons{
  display: inline-block;
}
.checkbox{
  height: 24px;
  width: 24px;
  margin-right: 5px;
}
.warringText{
  color: rgb(255, 0, 0);
  text-align: center;
}
</style>
