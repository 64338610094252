<template>
  <div class="borkerFormFive">
    <div class="textBigBox">
      <div class="tittleBox">
        <div class="tittle">
          <span>省核中，請耐心等待。</span>
        </div>
      </div>
      <div class="tittleBox">
        <div class="tittle">
          <span>我們會盡快審核您的資料，歡迎隨時登錄賬號查詢進度。▶（這可能會花費2-5個工作日）</span>
        </div>
      </div>
      <div class="tittleBox">
        <div class="tittle">
          <div class="spanBox1">
            <span>{{sec}}秒後跳回首頁</span>
          </div>
          <div class="spanBox2" @click="goCenter">
            <span>直接跳轉</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'BorkerFormFive',
  components: {

  },
  data() {
    return {
      sec:5
    }
  },
  methods:{
    sendCode(){
        const countDown = setInterval(()=>{
          this.sec-- ;
          if(this.sec == 0){
            clearInterval(countDown);
            window.location.href = '/usercenter'
          }
        },1000);
    },
    goCenter(){
      window.location.href = '/usercenter'
    }
  },
  mounted() {
    this.sendCode()
  },
}
</script>

<style scoped>
  .borkerFormFive{
    width: 100%;
    height: 100%;
  }
  .textBigBox{
    width: 640px;
    height: 100%;
    margin: 0 auto;
  }
  .tittleBox:nth-child(1){
    width: 100%;
    display: flex;
    /* margin-top: 167px; */
    padding-top: 167px;
  }
  .tittleBox:nth-child(2){
    width: 100%;
    display: flex;
    margin-top: 53px;
  }
  .tittleBox:nth-child(3){
    width: 100%;
    display: flex;
    margin-top: 137px;
  }
  .tittleBox:nth-child(1) .tittle{
    margin: 0 auto;
    font-family: Inter;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .tittleBox:nth-child(2) .tittle{
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .tittleBox:nth-child(3) .tittle{
    margin: 0 auto;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    display: inline-block;
  }
  .spanBox1{
    display: inline-block;
    margin-right: 42px;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .spanBox2{
    display: inline-block;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #5E69C7;
    cursor: pointer;
  }
</style>
