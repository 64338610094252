<template>
  <div class="borkerFormFourDFive">
    <div class="textBigBox">
        <div class="leftBox">
            <div class="drowDownMeunBox">
                <DrowDownMeun :meunHeight="420" titleName="Educational History">
                    <template v-slot:meun>
                        <div class="itemsBox">
                            <div class="itemsName">
                                <span>School Name</span>
                            </div>
                            <div class="inputBox">
                                <Validator type="text" :value="form.schoolName" :show="chickShow" @isCheckOkValue="schoolNameCheck">
                                    <input-text placeholder="University of Cambridge" v-model="form.schoolName"/>
                                </Validator>
                            </div>
                        </div>
                        <div class="itemsBox">
                            <div class="itemsName">
                                <span>Major</span>
                            </div>
                            <div class="inputBox">
                                <Validator type="text" :value="form.major" :show="chickShow" @isCheckOkValue="majorCheck">
                                    <input-text placeholder="University of Cambridge" v-model="form.major"/>
                                </Validator>
                            </div>
                        </div>
                        <div class="itemsBox">
                            <div class="itemsName">
                                <span>Major</span>
                            </div>
                            <div class="inputBox">
                                <Validator type="text" :value="form.major2" :show="chickShow" @isCheckOkValue="major2Check">
                                    <input-text placeholder="University of Cambridge" v-model="form.major2"/>
                                </Validator>
                            </div>
                        </div>
                        <div class="itemsBox">
                            <div class="itemsName">
                                <span>Doctoral degree</span>
                            </div>
                            <div class="inputBox">
                                <Validator type="drowDownMeun" defaultName="Select" :titleName="form.doctoralDegree" :show="chickShow" @isCheckOkValue="doctoralDegreeCheck">
                                    <select-menu :title="form.doctoralDegree" :width='152'>
                                        <option-menu v-for="item in doctoralDegreeList" :key="item.id" @click="doctoralDegree(item)">{{item.enName}}</option-menu>
                                    </select-menu>
                                </Validator>
                            </div>
                        </div>
                        <div class="itemsBox">
                            <div class="itemsName">
                                <span>Ddiploma</span>
                            </div>
                            <div class="inputBox">
                                <div class="fileUpLoadBox">
                                    <el-upload
                                        :on-success="onUploadSuccessImg"
                                        :before-upload="beforeAvatarUpload"
                                        :multiple="false"
                                        :action="avatarUrl"
                                        :data="{module:'avatar'}"
                                        :limit="1"
                                        class="uploadbtn">
                                        <ElUploadBtnStyle>
                                            <template v-slot:img>
                                            <img src="@/assets/xuImg/Rectangle138.png" v-if="!imgUrlBoolen">
                                            <img :src="form.imgUrl" v-if="imgUrlBoolen">
                                            </template>
                                        </ElUploadBtnStyle>
                                    </el-upload>
                                </div>
                                <div class="warringText" v-if="chickShow">
                                    <span>{{imgwarring}}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </DrowDownMeun>
            </div>
        </div>
        <div class="rightBox">
            <div class="itemsBox">
                <div class="itemsName">
                    <span>Please select upload file</span>
                </div>
            </div>
            <div class="itemsBox">
                <div class="chickBox" @click="TwoToOneBtn1">
                    <TwoToOneBtn :Active="Active" titleName="Authentication"/>
                </div>
                <div class="chickBox" @click="TwoToOneBtn2">
                    <TwoToOneBtn :Active="!Active" titleName="Passport"/>
                </div>
            </div>
            <div class="itemsBox">
                <div class="itemsName2">
                    <span>Nationality</span>
                </div>
                <div class="nationalityBox">
                    <Validator type="drowDownMeun" defaultName="Select" :titleName="form.nationality" :show="chickShow" @isCheckOkValue="nationalityCheck">
                        <select-menu :title="form.nationality" :width='152'>
                            <option-menu v-for="item in nationalityListOther" :key="item.id" @click="nationality(item)">{{item.name}}</option-menu>
                        </select-menu>
                    </Validator>
                </div>
            </div>
            <div class="itemsBox">
                <div class="itemsName">
                    <span>ID Number</span>
                </div>
            </div>
            <div class="itemsBox">
                <div class="inputBox">
                    <Validator type="number" :value="form.IdNumber" :show="chickShow" @isCheckOkValue="IdNumberCheck">
                        <input-text placeholder="University of Cambridge" v-model="form.IdNumber"/>
                    </Validator>
                </div>
            </div>
            <div class="itemsBox">
                <div class="inputBox">
                    <div class="fileUpLoadBox">
                        <el-upload
                            :on-success="onUploadSuccessImg2"
                            :before-upload="beforeAvatarUpload2"
                            :multiple="false"
                            :action="avatarUrl"
                            :data="{module:'idCard'}"
                            :limit="1"
                            class="uploadbtn">
                            <ElUploadBtnStyle>
                                <template v-slot:img>
                                <img src="@/assets/xuImg/Rectangle138.png" v-if="!imgUrlBoolen2">
                                <img :src="form.imgUrl2" v-if="imgUrlBoolen2">
                                </template>
                            </ElUploadBtnStyle>
                        </el-upload>
                    </div>
                    <div class="warringText" v-if="chickShow">
                        <span>{{imgwarring2}}</span>
                    </div>
                </div>
            </div>
            <div class="itemsBox">
                <div class="itemsName">
                    <span>
                        *注意事項<br/>
                        ‧您的證明必須為真實訊息<br/>
                        ‧請確認您的證明在有效期限內<br/>
                        ‧檔案必須為PNG、JPGE、PDF格式<br/>
                        ‧您的證明必須為清晰圖片，並且檔案大小不超過(3MB)<br/>
                    </span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import DrowDownMeun from '@/components/XuComponents/drowDownMeun/DrowDownMeun'
import SelectMenu from '@/components/selectMenu/SelectMenu'
import OptionMenu from '@/components/selectMenu/OptionMenu'
import InputText from '@/components/XuComponents/form/InputText'
import TwoToOneBtn from '@/components/XuComponents/btn/TwoToOneBtn'
import {getDictById,CountryList} from "@/axios/api/dict"
import Validator from '@/components/validator/Validator'
import ElUploadBtnStyle from '@/components/XuComponents/fileUpLoad/ElUploadBtnStyle'
export default {
  name:'BorkerFormFourDFive',
  components: {
      DrowDownMeun,
      SelectMenu,
      OptionMenu,
      InputText,
      TwoToOneBtn,
      Validator,
      ElUploadBtnStyle,
  },
  props:{
    chickShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
        Active:true,
        doctoralDegreeList:[],
        nationalityListOther:[],

        //圖片
        avatarUrl:"http://13.114.63.198:9119/admin/oss/file/upload",
        imgwarring:"*必須上傳檔案",
        imgwarring2:"*必須上傳檔案",
        imgUrlBoolen:false,
        imgUrlBoolen2:false,

        form:{
            schoolName:"",
            major:"",
            major2:"",
            doctoralDegree:"Select",
            idCardType:"Authentication",
            IdNumber:"",
            nationality:"Select",

            imgUrl:"",
            imgUrl2:"",
        },
        isCheckAllOkForm:{
            schoolNameCheck:false,
            majorCheck:false,
            major2Check:false,
            doctoralDegreeCheck:false,
            IdNumberCheck:false,
            nationalityCheck:false,

            imgCheck:false,
            imgCheck2:false,
        },
        isFormOk:false,
    }
  },
  methods:{
      doctoralDegree(item){
        this.form.doctoralDegree = item.enName
      },
      nationality(item){
          this.form.nationality = item.name
      },
      TwoToOneBtn1(){
        this.Active = true
        this.form.idCardType = "Authentication"
      },
      TwoToOneBtn2(){
        this.Active = false
        this.form.idCardType = "Passport"
      },
      doctoralDegreeListGet(){
        getDictById(13000).then(res =>{
            this.doctoralDegreeList = res.data.list
        })
      },
      countryNameListGet(){
        CountryList().then(res =>{
            this.nationalityListOther = res.data.items
        })
        },

        //上傳圖片
        onUploadSuccessImg(response){
            if(response.code==20000){
                this.form.imgUrl = response.data.url
                this.imgUrlBoolen = true
            }
            else{
                this.imgUrlBoolen = false
            }
        },
        onUploadSuccessImg2(response){
            if(response.code==20000){
                this.form.imgUrl2 = response.data.url
                this.imgUrlBoolen2 = true
            }
            else{
                this.imgUrlBoolen2 = false
            }
        },
        //圖片上傳前表單校驗
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.imgwarring="*上傳證件只能是 JPG 格式!"
                this.isCheckAllOkForm.imgCheck = false
            }
            else if(!isLt2M){
                this.imgwarring="*上傳證件大小不能超過 2MB!"
                this.isCheckAllOkForm.imgCheck = false
            }
            else{
                this.imgwarring=""
                this.isCheckAllOkForm.imgCheck = true
            }
            this.changPage()
            return isJPG && isLt2M;
        },
        beforeAvatarUpload2(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.imgwarring2="*上傳證件只能是 JPG 格式!"
                this.isCheckAllOkForm.imgCheck2 = false
            }
            else if(!isLt2M){
                this.imgwarring2="*上傳證件大小不能超過 2MB!"
                this.isCheckAllOkForm.imgCheck2 = false
            }
            else{
                this.imgwarring2=""
                this.isCheckAllOkForm.imgCheck2 = true
            }
            this.changPage()
            return isJPG && isLt2M;
        },

      schoolNameCheck(CheckBoolen){
          this.isCheckAllOkForm.schoolNameCheck = CheckBoolen
          this.changPage()
      },
      majorCheck(CheckBoolen){
          this.isCheckAllOkForm.majorCheck = CheckBoolen
          this.changPage()
      },
      major2Check(CheckBoolen){
          this.isCheckAllOkForm.major2Check = CheckBoolen
          this.changPage()
      },
      doctoralDegreeCheck(CheckBoolen){
          this.isCheckAllOkForm.doctoralDegreeCheck = CheckBoolen
          this.changPage()
      },
      IdNumberCheck(CheckBoolen){
          this.isCheckAllOkForm.IdNumberCheck = CheckBoolen
          this.changPage()
      },
      nationalityCheck(CheckBoolen){
          this.isCheckAllOkForm.nationalityCheck = CheckBoolen
          this.changPage()
      },
      changPage(){
          if(this.isCheckAllOkForm.schoolNameCheck == true 
          && this.isCheckAllOkForm.majorCheck == true 
          && this.isCheckAllOkForm.major2Check == true 
          && this.isCheckAllOkForm.doctoralDegreeCheck == true 
          && this.isCheckAllOkForm.IdNumberCheck == true 
          && this.isCheckAllOkForm.nationalityCheck == true 
          && this.isCheckAllOkForm.imgCheck == true 
          && this.isCheckAllOkForm.imgCheck2 == true ){
            this.isFormOk = true
            this.$emit('isFormOk', this.isFormOk)
            this.$emit('form', this.form)
          }
          else{
            this.isFormOk = false
            this.$emit('isFormOk', this.isFormOk)
          }
          
      }
  },
  mounted(){
      this.doctoralDegreeListGet()
      this.countryNameListGet()
  }
}
</script>

<style scoped>
.borkerFormFourDFive{
    width: 100%;
    height: 100%;
    display: flex;
}
.textBigBox{
  height: 100%;
  margin: 0 auto;
  display: flex;
}
.leftBox{
  margin-right: 152px;
}
.drowDownMeunBox{
  padding-top: 70px;
  width: 418px;
}
.itemsBox{
  width: 100%;
  display: flex;
}
.itemsName{
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-right: 40px;
  padding-left: 20px;
}
.itemsBox:nth-child(1){
  margin-bottom: 0px;
}
.itemsBox:nth-child(2){
  margin-bottom: 0;
}
.itemsBox:nth-child(3){
  margin-bottom: 0;
}
.itemsBox:nth-child(4){
  margin-bottom: 30px;
}
.fileUpLoadBox{
  width: 246px;
  height: 138px;
  border-radius: 7px;
  overflow: hidden;
}
.rightBox .itemsBox .itemsName{
    margin-right: 0px;
}
.rightBox .itemsBox:nth-child(1){
    padding-top: 70px;
}
.rightBox .itemsBox:nth-child(2){
    margin-bottom: 28px;
}
.rightBox .itemsBox:nth-child(3){
    margin-bottom: 0px;
    padding-left: 20px;
}
.rightBox .itemsBox:nth-child(4){
    margin-bottom: 0px;
}
.rightBox .itemsBox:nth-child(5){
    margin-bottom: 0px;
}
.rightBox .itemsBox:nth-child(5) .inputBox{
    padding-left: 20px;
}
.rightBox .itemsBox:nth-child(6){
    margin-bottom: 20px;
}
.rightBox .itemsBox:nth-child(6) .inputBox{
    padding-left: 20px;
}
.rightBox .itemsBox:nth-child(7) .itemsName{
    line-height: 30px;
}
.chickBox{
    margin-left:20px;
    height: 30px;
}
img{
    width: 100%;
    height: 100%;
}
.itemsName2{
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-right: 40px;
}
.warringText{
  text-align: right;
  margin-left: 20px;

  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
}
.uploadbtn{
  width: 100%;
  height: 100%;
}
img{
  width: 100%;
}
</style>
