<template>
<!-- 個人經濟 -->
  <div class="borker">
    <div class="left">
      <LeftBar :page="page">
        <template v-slot:spanBox>
          <div class="divBox">
            <div class="centerLine">
              <div class="span">
                <span>{{leftBarTittle}}</span>
              </div>
              <div class="span">
                <span>{{leftBarTextTwo}}</span>
              </div>
              <div class="span">
                <span>遇上問題? 技術支持</span>
              </div>
              <div class="span">
                <span>關於隱私</span>
              </div>
              <div class="span">
                <span>知識產權</span>
              </div>
              <div class="span">
                <span>網路安全法</span>
              </div>
            </div>
          </div>
        </template>
      </LeftBar>
    </div>
    <div class="right">
      <div class="program">
        <NumberNavBar :page="page" :allPage="allPage">
          <template v-slot:1>
            <span>Personal information1</span>
          </template>
          <template v-slot:7>
            <span>About you1</span>
          </template>
          <template v-slot:2>
            <span>Personal information2</span>
          </template>
          <template v-slot:8>
            <span>About you2</span>
          </template>
          <template v-slot:3>
            <span>Personal information3</span>
          </template>
          <template v-slot:9>
            <span>About you3</span>
          </template>
          <template v-slot:4>
            <span>Personal information4</span>
          </template>
          <template v-slot:10>
            <span>About you4</span>
          </template>
          <template v-slot:5>
            <span>Personal information5</span>
          </template>
          <template v-slot:11>
            <span>About you5</span>
          </template>
          <template v-slot:6>
            <span>Personal information6</span>
          </template>
          <template v-slot:12>
            <span>About you6</span>
          </template>
        </NumberNavBar>
      </div>
      <div class="pageBox">
        <BorkerFormOne v-if="page==1" @isFormOk="isFormOneOk" :chickShow="pageChickShow"></BorkerFormOne>
        <BorkerFormTwo v-if="page==2" @isFormOk="isFormTwoOk" @form="pageTwoForm" :chickShow="pageChickShow"></BorkerFormTwo>
        <BorkerFormThree v-if="page==3" @isFormOk="isFormThreeOk" @form="pageThreeForm" :chickShow="pageChickShow"></BorkerFormThree>
        <BorkerFormFour v-if="page==4" @isFormOk="isFormFourOk" @form="pageFourForm" :chickShow="pageChickShow"></BorkerFormFour>
        <BorkerFormFourDFive v-if="page==5" @isFormOk="isFormFourDFiveOk" @form="pageFourDFiveForm" :chickShow="pageChickShow"></BorkerFormFourDFive>
        <BorkerFormFive v-if="page==6"></BorkerFormFive>
        <!-- <BorkerFormSix v-if="page==7"></BorkerFormSix> -->
      </div>
      <div class="btnBox">
        <div class="btnBack" v-show="btnBackShow">
          <BtnSubmit color="red" @click="goBack()">{{btnBack}}</BtnSubmit>
        </div>
        <div class="btnNext" v-show="btnNextShow">
          <BtnSubmit @click="goNext()">{{btnNext}}</BtnSubmit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BtnSubmit from '@/components/button/BtnSubmit'
import BorkerFormOne from '@/views/login/joinUs/borker/borkerPage/BorkerFormOne'
import BorkerFormTwo from '@/views/login/joinUs/borker/borkerPage/BorkerFormTwo'
import BorkerFormThree from '@/views/login/joinUs/borker/borkerPage/BorkerFormThree'
import BorkerFormFour from '@/views/login/joinUs/borker/borkerPage/BorkerFormFour'
import BorkerFormFourDFive from '@/views/login/joinUs/borker/borkerPage/BorkerFormFourDFive'
import BorkerFormFive from '@/views/login/joinUs/borker/borkerPage/BorkerFormFive'
// import BorkerFormSix from '@/views/login/joinUs/borker/borkerPage/BorkerFormSix'
import LeftBar from '@/views/login/joinUs/components/leftBar/LeftBar'
import NumberNavBar from '@/views/login/joinUs/components/numberNavBar/NumberNavBar'
import {ProveImageController,BorkerPost} from "@/axios/api/dict"

export default {
  name:'Borker',
  components: {
    BtnSubmit,
    BorkerFormOne,
    BorkerFormTwo,
    BorkerFormThree,
    BorkerFormFour,
    BorkerFormFourDFive,
    BorkerFormFive,
    // BorkerFormSix,
    LeftBar,
    NumberNavBar
  },
  data() {
    return {
      btnBack:'Back',
      btnNext:'Next',
      page:1,
      allPage:6,
      btnBackShow:false,
      btnNextShow:true,

      //LeftBar
      leftBarTittle:"監管聲明",
      leftBarTextTwo:"提供虛假信息是非法行為，Finger可以隨時停止您的服務",

      //表單Warring顯示
      pageChickShow:false,

      //換頁面Boolen
      nextBoolen:false,

      //要Post出去的表單
      postForm:{ //http://13.114.63.198:8989/api/user/ucenter/broker/openAccount
        brokerType:"",
        brokersNumber: "",
        firstName: "",
        midName: "",
        lastName: "",
        birth: "",
        gender: "",
        brokerNumber: "",
        phone:"",
        address: "",
        zipCode: "",
        city: "",
        district: "",
        contactName: "",
        contactPhone: "",

        schoolName: "",
        major: "",
        minor: "",
        degree: "",
        identityId: "",
        isHasLicense: "",
        nationality: "",

        imgDegree: "",
        licenseId: "",
        licenseIdTwo: "",
        licenseIdThree: ""
      },
      postForm2:{ //http://13.114.63.198:8088/api/crm/prove-image/save-prove-img
        fileCountry:"",
        fileType: "",
        issuingAuthority:"",
        periodValidity:"",
        imageUrl: "",
      },
      postForm3:{ //http://13.114.63.198:8088/api/crm/prove-image/save-prove-img
        fileCountry:"",
        fileType: "",
        issuingAuthority:"",
        periodValidity:"",
        imageUrl: "",
      },
      postForm4:{ //http://13.114.63.198:8088/api/crm/prove-image/save-prove-img
        fileCountry:"",
        fileType: "",
        issuingAuthority:"",
        periodValidity:"",
        imageUrl: "",
      },

    }
  },
  methods:{
    goBack(){
      if(this.page > 1){
        this.page--
      }
    },
    goNext(){
      if(this.nextBoolen == true && this.page == 4){
        ProveImageController(this.postForm2).then(res =>{
          if(res.code == 20000){
            this.postForm.licenseId = res.data.proveImage
          }
          else{
            console.log(res.message)
          }
        })
        ProveImageController(this.postForm3).then(res =>{
          if(res.code == 20000){
            this.postForm.licenseIdTwo = res.data.proveImage
          }
          else{
            console.log(res.message)
          }
        })
        ProveImageController(this.postForm4).then(res =>{
          if(res.code == 20000){
            this.postForm.licenseIdThree = res.data.proveImage
          }
          else{
            console.log(res.message)
          }
        })
      }

      if(this.nextBoolen == true && this.page == 5){
        BorkerPost(this.postForm)
      }

      if(this.nextBoolen == true && this.page < this.allPage){
        this.page++
        this.nextBoolen = false
        this.pageChickShow = false
      }
      else{
        this.pageChickShow = true
      }
    },
    btnShow(){
      if(this.page == 1){
        this.btnBackShow = false
        this.btnNextShow = true
        this.btnNext = "Next"
      }
      else if(this.page < this.allPage){
        this.btnBackShow = true
        this.btnNextShow = true
        this.btnNext = "Next"
      }
      else{
        this.btnNext = "Finish"
      }
    },
    isFormOneOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormTwoOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormThreeOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormFourOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    isFormFourDFiveOk(CheckBoolen){
      if(CheckBoolen == true){
        this.nextBoolen = true
      }
      else{
        this.nextBoolen = false
      }
    },
    pageTwoForm(form){
      this.postForm.brokerType = form.borkerTitle
      this.postForm.brokersNumber = form.BrokerageNumber
    },
    pageThreeForm(form){
      this.postForm.firstName = form.firstName
      this.postForm.midName = form.midName
      this.postForm.lastName = form.lastName
      this.postForm.birth = form.dateBirth
      if(form.sexTitle == "Male"){
        this.postForm.gender = true
      }
      else{
        this.postForm.gender = false
      }
      this.postForm.phone = form.phoneTitle + "_" + form.PphoneNumberOne
      this.postForm.address = form.Address
      this.postForm.zipCode = form.ZIP
      this.postForm.city = form.City
      this.postForm.district = form.District
      this.postForm.contactName = form.EmergencyFirstName + "_" + form.EmergencyMidName + "_" + form.EmergencyLastName
      this.postForm.contactPhone = form.EmergencyPhoneNumber
    },
    pageFourForm(form){
      if(form.qualificationCertificate == true){
        this.postForm.isHasLicense = "true"
      }
      else{
        this.postForm.isHasLicense = "false"
      }

      this.postForm2.fileCountry = form.countryName
      this.postForm2.fileType = form.brokerType
      this.postForm2.issuingAuthority = form.issuingAuthorityName
      this.postForm2.periodValidity = form.licenseNumber
      this.postForm2.imageUrl = form.imgUrl1

      this.postForm3.fileCountry = form.countryNameAnalyst
      this.postForm3.fileType = form.brokerTypeAnalyst
      this.postForm3.issuingAuthority = form.issuingAuthorityNameAnalyst
      this.postForm3.periodValidity = form.licenseNumberAnalyst
      this.postForm3.imageUrl = form.imgUrl2

      this.postForm4.fileCountry = form.countryNameOther
      this.postForm4.fileType = form.brokerTypeOther
      this.postForm4.issuingAuthority = form.issuingAuthorityNameOther
      this.postForm4.periodValidity = form.licenseNumberOther
      this.postForm4.imageUrl = form.imgUrl3
    },
    pageFourDFiveForm(form){
      this.postForm.schoolName = form.schoolName
      this.postForm.major = form.major
      this.postForm.minor = form.major2
      this.postForm.degree = form.doctoralDegree
      this.postForm.identityId = form.IdNumber
      this.postForm.nationality = form.nationality
      this.postForm.imgDegree = form.imgUrl
    }
  },
  watch:{
    "page"(){
      this.btnShow()
      // console.log(this.page)
      if(this.page == 1){
        this.leftBarTittle = "監管聲明"
        this.leftBarTextTwo = "提供虛假信息是非法行為，Finger可以隨時停止您的服務"
      }
      else if(this.page == 2){
        this.leftBarTittle = "個人資訊"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else if(this.page == 3){
        this.leftBarTittle = "個人資訊"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else if(this.page == 4){
        this.leftBarTittle = "資格證書"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else if(this.page == 5){
        this.leftBarTittle = "監管聲明"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
      else{
        this.leftBarTittle = "個人資訊"
        this.leftBarTextTwo = "請輸入您的個人訊息，並確保這些資料為真實訊息，讓我們方便為您提供接下來的服務。"
      }
    }
  }
}
</script>

<style scoped>
  *{
    position: relative;
  }
  .borker{
    position: relative;
    width: 100vw;
    height: 100%;
    background: #f9f9f9;
    display: flex;
  }
  .left{
    width: 20%;
    height: 100%;
    display: inline-block;
  }
  .right{
    width: 80%;
    height: 100%;
    display: inline-block;
  }
  .program{
    margin-top: 37px;
    width: 100%;
    height: 115px;
  }
  .pageBox{
    width: 100%;
    height: 65%;
    border-radius: 20px;
  }
  .btnBox{
    width: 100%;
    height: 50px;
    display: flex;
  }
  .btnBack{
    position: absolute;
    left: 210px;
    top: 0;
  }
  .btnNext{
    position: absolute;
    right: 210px;
    top: 0;
  }

  /*LeftBar*/
  .divBox{
    width: 100%;
    height: 100%;
    display: flex;
  }
  .centerLine{
    height: 100%;
    position: relative;
  }
  .span{
    font-family: Inter;
    font-style: normal;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 40px;
  }
  .span:nth-child(1){
    font-size: 72px;
    font-weight: 800;
    margin-top: 50px;
    margin-bottom: 36px;
  }
  .span:nth-child(2){
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 36px;
    max-width: 300px;
  }
  .span:nth-child(3){
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 255px;
  }
  .span:nth-child(3):hover{
    color: #0099ff;
    font-size: 20px;
  }
  .span:nth-child(4),.span:nth-child(5),.span:nth-child(6){
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .span:nth-child(4):hover,.span:nth-child(5):hover,.span:nth-child(6):hover{
    color: #0099ff;
    font-size: 20px;
  }
</style>
