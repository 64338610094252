<template>
  <div class="chickBtn" @click="btnClick" ref="chickBtn">
      <div class="chickBtnItem" ref="chickBtnItem">
          <div class="spanBox">
              <span>{{tittleName}}</span>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name:'ChickBtn',
  components: {

  },
  props:{
      falseName:{
        type:String,
      },
      trueName:{
        type:String,
      }
  },
  data() {
    return {
        btnClickBoolen:false,
        tittleName:""
    }
  },
  methods:{
    btnClick(){
        this.btnClickBoolen = !this.btnClickBoolen
    },
    chickBtnStyle(){
        if(this.btnClickBoolen == false){
            this.$refs.chickBtn.style = "background-color: rgb(212, 21, 21);"
            this.$refs.chickBtnItem.style = "left: 40%;"
            this.tittleName = this.falseName
            this.$emit('backValue', this.btnClickBoolen)
        }
        else{
            this.$refs.chickBtn.style = "background-color: rgb(3, 169, 244);"
            this.$refs.chickBtnItem.style = "left: 0%;"
            this.tittleName = this.trueName
            this.$emit('backValue', this.btnClickBoolen)
        }
    },
    createdTittleName(){
        this.tittleName = this.falseName
    }
  },
  created(){
    this.createdTittleName()
  },
  watch:{
    "btnClickBoolen"(){
        this.chickBtnStyle()
    }
  }
}
</script>

<style scoped>
.chickBtn{
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-color: rgb(212, 21, 21);
    border-radius: 10px;
    transition: .3s;
    position: relative;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
    border: rgb(97, 97, 97) 1px solid;
}
.chickBtnItem{
    position: absolute;
    top: 0;
    left: 40%;
    width: 60%;
    height: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.18);
    border: rgb(97, 97, 97) 1px solid;
    box-sizing: border-box;
    transition: .3s;
    display: flex;
}
.spanBox{
    margin: 0 auto;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
}

</style>
